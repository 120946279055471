<template>
    <ContentLayout icon="mdi-car" :title="title">
        <router-link :to="{ name: 'inventory' }">
            <v-btn color="primary" fab dark absolute top right>
                <v-icon>mdi-car-multiple</v-icon>
            </v-btn>
        </router-link>
        <v-container fluid ref="item">
            <v-row v-if="!fetching">
                <v-col cols="12" md="6">
                    <v-card elevation="8" color="primary">
                        <v-carousel
                            continuous
                            show-arrows-on-hover
                            reverse-transition="fade-transition"
                            transition="fade-transition"
                            height="400"
                            hide-delimiter-background
                            :key="updatePhotos"
                        > <!-- cycle -->
                            <template v-if="item.photos.length > 0">
                                <v-carousel-item v-for="(photo, i) in item.photos" :key="i">
                                    <v-img
                                        :src="photo.url"
                                        height="100%"
                                        width="100%"
                                    >
                                        <v-row v-if="$store.state.user">
                                            <v-col>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" @click="defaultPhoto(photo)" class="ma-2"><v-icon>{{ photo.is_default ? "mdi-star" : "mdi-star-outline" }}</v-icon></v-btn>
                                                    </template>
                                                    Marquer comme photo principale
                                                </v-tooltip>
                                            </v-col>
                                            <v-col class="text-right">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn v-on="on" @click="deletePhoto(photo)" class="ma-2"><v-icon>mdi-delete</v-icon></v-btn>
                                                    </template>
                                                    Supprimer
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-img>
                                </v-carousel-item>
                            </template>
                            <v-carousel-item v-else>
                                <v-sheet color="grey" height="105%" tile>
                                    <v-row class="fill-height" align="center" justify="center" v-if="$store.state.user">
                                        <v-col cols="12" class="text-center">
                                            <h1 class="display-3 mb-4">
                                                Aucune photo
                                            </h1>
                                            <h4 class="subtitle-1">
                                                Cliquer sur le bouton ci-dessous pour en ajouter.
                                            </h4>
                                        </v-col>
                                    </v-row>
                                    <v-img
                                        v-else
                                        :src="require('./../../assets/img/coming-soon.png')"
                                        height="100%"
                                        width="100%"
                                    ></v-img>
                                </v-sheet>
                            </v-carousel-item>
                        </v-carousel>
                        <template v-if="$store.state.user">
                            <input type="file" multiple v-show="false" ref="photos" accept=".jpg,.jpeg" @change="loadPhotos" />
                            <v-btn block text dark @click="$refs.photos.click();" :loading="saving"><v-icon left>mdi-image-plus</v-icon>Ajouter une photo</v-btn>
                        </template>
                    </v-card>
                    
                    <router-link :to="{ name: 'inventory' }" class="text-decoration-none">
                        <v-btn block text color="primary" class="mt-4"><v-icon left>mdi-car-multiple</v-icon>Retourner à l'inventaire</v-btn>
                    </router-link>
                </v-col>
                <v-col cols="12" md="6">
                    <template v-if="$store.state.user">
                        <v-form ref="form" @submit.prevent @submit="save">
                            <v-row dense>
                                <v-col cols="12">
                                    <v-combobox
                                        label="Marque / Modèle"
                                        v-model="item.brand_model"
                                        :items="vehicles"
                                        :rules="[ $data._rules.required ]"
                                        :loading="fetchingVehicles"
                                    ></v-combobox>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        label="Description du modèle"
                                        v-model="item.model_description"
                                        :rules="[ $data._rules.required ]"
                                        maxlength="64"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        label="Année"
                                        v-model="item.year"
                                        :rules="[ $data._rules.required ]"
                                        v-mask="'####'"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-autocomplete
                                        label="Couleur"
                                        v-model="item.color"
                                        :items="colors"
                                        :rules="[ $data._rules.required ]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        label="Kilométrage"
                                        v-model="item.kilometrage"
                                        :rules="[ $data._rules.required ]"
                                        v-mask="'########'"
                                    >
                                        <template v-slot:append>KM</template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-autocomplete
                                        label="Transmission"
                                        v-model="item.transmission"
                                        :items="transmissions"
                                        :rules="[ $data._rules.required ]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-autocomplete
                                        label="Traction"
                                        v-model="item.traction"
                                        :items="tractions"
                                        :rules="[ $data._rules.required ]"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        label="Description"
                                        v-model="item.description"
                                        rows="3"
                                        auto-grow
                                    ></v-textarea>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Prix"
                                        v-model="item.price"
                                        :rules="[ $data._rules.required, $data._rules.number ]"
                                        maxlength="10"
                                    >
                                        <template v-slot:append>$</template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox
                                        label="Vendu"
                                        :value="sold"
                                        @change="v => item.sold_at = v ? _getDate() : null"
                                        :hint="item.sold_at"
                                        :persistent-hint="item.sold_at ? true : false"
                                    ></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-card color="grey" dark class="my-4">
                                <v-card-title><v-icon left>mdi-currency-usd</v-icon>Montants sur véhicule</v-card-title>
                                <v-card-text>
                                    <v-row dense v-for="(item, i) in item.amounts" :key="i">
                                        <v-col cols="7">
                                            <v-text-field
                                                label="Description"
                                                v-model="item.description"
                                                maxlength="128"
                                                solo light
                                                hide-details
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="5">
                                            <v-text-field
                                                label="Montant"
                                                v-model="item.amount"
                                                :rules="[ $data._rules.number ]"
                                                maxlength="10"
                                                solo light
                                                hide-details
                                            >
                                                <template v-slot:append>$</template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row dense v-if="total > 0">
                                        <v-col cols="7"></v-col>
                                        <v-col cols="5">
                                            <v-text-field
                                                label="Montant total"
                                                :value="total"
                                                :rules="[ $data._rules.number ]"
                                                maxlength="10"
                                                solo flat light
                                                hide-details
                                                readonly
                                            >
                                                <template v-slot:append>$</template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-row dense>
                                <v-col cols="12" class="text-right">
                                    <v-tooltip left v-if="item.id">
                                        <template v-slot:activator="{ on }">
                                            <v-btn
                                                v-on="on"
                                                icon color="error"
                                                :loading="deleting"
                                                :disabled="saving"
                                                @click="remove(item.id)"
                                            ><v-icon>mdi-delete</v-icon></v-btn>
                                        </template>
                                        Supprimer
                                    </v-tooltip>
                                    <v-btn color="primary" type="submit" :loading="saving" :disabled="deleting"><v-icon left>mdi-content-save</v-icon>Sauvegarder</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </template>
                    <template v-else>
                        <div>
                            <v-card dark>
                                <v-card-title class="primary">{{ title }}</v-card-title>
                                <v-card-subtitle class="primary">{{ item.model_description }}</v-card-subtitle>
                                <v-row no-gutters>
                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-wrap">{{ item.year }}</v-list-item-title>
                                                <v-list-item-subtitle>Année</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-wrap">{{ item.color }}</v-list-item-title>
                                                <v-list-item-subtitle>Couleur</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-wrap">{{ item.kilometrage }} KM</v-list-item-title>
                                                <v-list-item-subtitle>Kilométrage</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-wrap">{{ item.transmission }}</v-list-item-title>
                                                <v-list-item-subtitle>Transmission</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item two-line>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-wrap">{{ item.traction }}</v-list-item-title>
                                                <v-list-item-subtitle>Traction</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item two-line v-if="description">
                                            <v-list-item-content>
                                                <v-list-item-title class="text-wrap" v-html="description"></v-list-item-title>
                                                <v-list-item-subtitle>Description</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                                <v-card-title class="primary">
                                    <template v-if="!item.sold_at">
                                        <span class="mr-2">Notre prix:</span><strong><span class="secondary--text">{{ item.price }} $</span></strong>
                                    </template>
                                    <template v-else>
                                        <!--<v-spacer></v-spacer>-->
                                        <v-chip label color="error"><v-icon left small>mdi-check</v-icon>VENDU</v-chip>
                                    </template>
                                </v-card-title>
                            </v-card>
                            <v-row dense class="mt-2">
                                <v-col cols="12" md="4">
                                    <v-btn
                                        large color="primary" block
                                        @click="$root.fundingDialog.open()"
                                    >
                                        <v-icon left>mdi-currency-usd</v-icon>Financement
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-btn
                                        large color="secondary" class="primary--text" block
                                        :href="'tel:' + $store.state.data.phone"
                                    >
                                        <v-icon left>mdi-phone</v-icon>{{ $store.state.data.phone }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-btn
                                        large color="info" block
                                        :href="'https://m.me/' + $store.state.data.facebook"
                                        target="_blank"
                                    >
                                        <v-icon left>mdi-facebook-messenger</v-icon>Messenger
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </v-col>
            </v-row>
            <div class="text-center" v-else>
                <v-progress-circular
                    indeterminate
                    color="primary"
                    size="80"
                ></v-progress-circular>
            </div>
        </v-container>
    </ContentLayout>
</template>

<script>
    // Imports
    import ContentLayout from "./../layouts/Content";

    // Module
    export default {
        name: "item-view",
        components: {
            ContentLayout
        },
        data: () => ({
            fetching: false,
            saving: false,
            deleting: false,
            item: {
                id: null,
                created_at: null,
                updated_at: null,
                deleted_at: null,
                brand_model: null,
                brand: null,
                model: null,
                model_description: null,
                year: null,
                color: null,
                kilometrage: null,
                transmission: null,
                traction: null,
                description: null,
                price: null,
                sold_at: null,
                amounts: [
                    { description: "", amount: "" }
                ],
                photos: []
            },
            defaults: null,
            fetchingVehicles: false,
            vehicles: [],

            colors: [ "Blanc", "Bleu", "Brun", "Gris", "Jaune", "Mauve", "Noir", "Orange", "Rose", "Rouge", "Vert", "Autre" ],
            transmissions: [ "Automatique", "Manuelle" ],
            tractions: [ "AWD", "FWD", "RWD" ],

            updatePhotos: 0
        }),
        computed: {
            title() {
                return this.item.id ? this.item.brand + " " + this.item.model + " " + this.item.year : "Ajout d'un véhicule";
            },
            description() {
                return this.item.description ? this.item.description.replace(/\n/g, "<br />") : null;
            },
            total() {
                let total = 0;
                for(let i = 0; i < this.item.amounts.length; i++) {
                    if(!isNaN(parseFloat(this.item.amounts[i].amount))) {
                        total += parseFloat(this.item.amounts[i].amount);
                    }
                }
                return total.toFixed(2);
            },
            sold() {
                return this.item.sold_at ? true : false;
            }
        },
        watch: {
            "item.photos": {
                deep: true,
                handler() {
                    for(let i = 0; i < this.item.photos.length; i++) {
                        if(!this.item.photos[i].url) {
                            fetch(this.item.photos[i].data).then(r => r.blob()).then(blob => {
                                this.item.photos[i].url = window.URL.createObjectURL(blob);
                                //console.log("blob", blob);
                                this.updatePhotos++;
                            });
                        }
                    }
                }
            },
            "item.amounts": {
                deep: true,
                handler(items) {
                    if(items.length === 0 || (items.length > 0 && items[items.length - 1].amount)) {
                        items.push({ description: "", amount: "" });
                    }
                }
            },

            "$store.state.user": function(value) {
                if(this.$route.name != "item") {
                    return;
                }
                if(value) {
                    this.fetchVehicles();
                } else {
                    this.fetch(this.item.id);
                }
            }
        },
        methods: {
            fetch(id) {
                //console.log("fetch", id);
                this.item = JSON.parse(this.defaults);
                if(this.$refs.form) {
                    this.$refs.form.resetValidation();
                }
                if(id) {
                    this.fetching = true;
                    this._apiRequest("items/get", { id: id }).then(response => {
                        if(!response.error) {
                            let photos = response.item.photos && response.item.photos.length > 0 ? response.item.photos : [];
                            if(photos.length > 0) {
                                for(let i = 0; i < photos.length; i++) {
                                    photos[i].is_default = parseInt(photos[i].is_default);
                                }
                            }
                            photos = photos.sort((a, b) => a.is_default == b.is_default ? 0 : a.is_default < b.is_default ? 1 : -1);
                            this.item = response.item;
                        } else {
                            this.$root.snackbar.show({
                                text: response.error,
                                icon: "mdi-alert-circle",
                                color: "error"
                            });
                        }
                    }).finally(() => {
                        this.fetching = false;

                        if(this.$refs && this.$refs.item) {
                            this.$vuetify.goTo(this.$refs.item, { duration: 200, offset: -3, easings: "easeInOutCubic" });
                        }
                    });
                }
            },
            save() {
                //console.log("save");
                if(this.$refs && this.$refs.form && this.$refs.form.validate()) {
                    let item = JSON.parse(JSON.stringify(this.item));
                    item.amounts = item.amounts.filter(amount => amount.amount > 0);
                    this.saving = true;
                    this._apiRequest("items/" + (this.item.id ? "update" : "create"), item).then(response => {
                        if(!response.error) {
                            this.$root.snackbar.show({
                                text: "Le véhicule a été sauvegardé avec succès!",
                                icon: "mdi-check",
                                color: "success"
                            });
                            if(response.id) {
                                //this.item.id = response.id;
                                this.fetch(response.id);
                                this.$router.push({ name: "item", params: { id: response.id }});
                            }
                        } else {
                            this.$root.snackbar.show({
                                text: response.error,
                                icon: "mdi-alert-circle",
                                color: "error"
                            });
                        }
                    }).finally(() => {
                        this.saving = false;
                    });
                } else {
                    this.$root.snackbar.show({
                        text: "Il y a quelques erreurs dans le formulaire. Veuillez remplir tous les champs.",
                        icon: "mdi-alert-circle",
                        color: "error"
                    });
                }
            },
            remove(id) {
                if(confirm("Voulez-vous vraiment supprimer ce véhicule?")) {
                    this.deleting = true;
                    this._apiRequest("items/delete", { id: id }).then(response => {
                        if(!response.error) {
                            this.$root.snackbar.show({
                                text: "Le véhicule a été supprimé avec succès!",
                                icon: "mdi-check",
                                color: "success"
                            });
                            this.$router.push({ name: "inventory" });
                        } else {
                            this.$root.snackbar.show({
                                text: response.error,
                                icon: "mdi-alert-circle",
                                color: "error"
                            });
                        }
                    }).finally(() => {
                        this.deleting = false;
                    });
                }
            },
            loadPhotos() {
                let files = this.$refs.photos && this.$refs.photos.files && this.$refs.photos.files.length > 0 ? this.$refs.photos.files : null;
                if(files && files.length > 0) {
                    for(let i = 0; i < files.length; i++) {
                        let img = new Image();
                        img.onload = () => {
                            // Resize
                            let canvas = document.createElement("canvas");
                            let ctx = canvas.getContext("2d");
                            let scale = 800 / img.height;
                            canvas.width = img.width * scale;
                            canvas.height = img.height * scale;
                            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                            let data = canvas.toDataURL(files[i].type, 0.6);
                            
                            // Set
                            this.item.photos.push({
                                data: data,
                                url: img.src,
                                is_default: this.item.photos.length === 0 ? 1 : 0
                            });
                        }
                        img.src = window.URL.createObjectURL(files[i]);
                    }
                }
            },
            defaultPhoto(photo) {
                let defaultPhoto = this.item.photos.find(photo => photo.is_default);
                if(defaultPhoto) {
                    defaultPhoto.is_default = 0;
                }
                photo.is_default = 1;
                this.item.photos = this.item.photos.sort((a, b) => a.is_default == b.is_default ? 0 : a.is_default < b.is_default ? 1 : -1);
            },
            deletePhoto(photo) {
                let is_default = photo.is_default;
                let index = this.item.photos.indexOf(photo);
                this.item.photos.splice(index, 1);
                if(is_default && this.item.photos.length > 0) {
                    this.item.photos[0].is_default = 1;
                }
            },

            fetchVehicles() {
                if(this.$store.state.user && this.vehicles.length === 0) {
                    this.fetchingVehicles = true;
                    this._apiRequest("vehicles/get-all").then(response => {
                        if(!response.error) {
                            this.vehicles = response.vehicles.map(v => v.text);
                        } else {
                            this.$root.snackbar.show({
                                text: response.error,
                                icon: "mdi-alert-circle",
                                color: "error"
                            });
                        }
                    }).finally(() => {
                        this.fetchingVehicles = false;
                    });
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            //console.log("beforeRouteEnter");
            if(!to.params.id && !window.store.state.user) {
                next({ name: "inventory" });
            }
            next(true);
        },
        created() {
            //console.log("created");
            this.defaults = JSON.stringify(this.item);
        },
        activated() {
            //console.log("activated");
            this.fetchVehicles();
            this.fetch(this.$route.params.id);
        }
    };
</script>

<style scoped>
    .text-wrap {
        text-wrap: wrap;
    }
</style>