<template>
    <v-app v-if="$store.state.data">
        <FundingDialog ref="fundingDialog"></FundingDialog>
        <SettingsDialog ref="settingsDialog"></SettingsDialog>
        <SnackbarElement ref="snackbar"></SnackbarElement>
        <LoginDialog ref="loginDialog"></LoginDialog>
        <SidebarElement :drawer="drawer" @update="v => drawer = v"></SidebarElement>
        <v-app-bar app dark flat color="primary" height="80">
            <v-app-bar-title>
                <router-link to="/">
                    <v-img
                        alt="Logo"
                        contain
                        :src="require('./../assets/img/logo+text-dark.svg')"
                        transition="scale-transition"
                        width="250"
                    />
                </router-link>
            </v-app-bar-title>
            <v-spacer></v-spacer>
            <SearchElement v-if="$vuetify.breakpoint.lgAndUp" />
            <v-spacer></v-spacer>
            <NavigationElement v-if="$vuetify.breakpoint.mdAndUp" />
            <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                color="accent"
                outlined
                depressed
                large
                class="ml-2"
                @click="$root.fundingDialog.open()"
            >
                <v-icon left>mdi-currency-usd</v-icon>Financement
            </v-btn>
            <v-btn
                v-if="$vuetify.breakpoint.smAndUp"
                large color="secondary" class="primary--text" :class="{ 'ml-2': $vuetify.breakpoint.mdAndUp }"
                :href="'tel:' + $store.state.data.phone"
            >
                <v-icon left>mdi-phone</v-icon>{{ $store.state.data.phone }}
            </v-btn>

            <!-- Session -->
            <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-tooltip bottom v-if="$store.state.user && $store.state.user.is_admin">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="$root.settingsDialog.open()">
                            <v-icon>mdi-cog</v-icon>
                        </v-btn>
                    </template>
                    Paramètres
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            @click="$store.state.user ? $root.loginDialog.logout() : $root.loginDialog.open()"
                            :loading="fetching"
                            icon
                        >
                            <v-icon>{{ $store.state.user ? "mdi-logout" : "mdi-login" }}</v-icon>
                        </v-btn>
                    </template>
                    {{ $store.state.user ? "Déconnexion" : "Connexion" }}
                </v-tooltip>
            </template>
            <!-- /Session -->

            <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click="drawer = true"></v-app-bar-nav-icon>
        </v-app-bar>

        <v-main>
            <SearchElement v-if="$vuetify.breakpoint.mdAndDown" full-width />
            <v-btn
                v-if="$vuetify.breakpoint.smAndDown"
                large color="accent" block tile
                @click="$root.fundingDialog.open()"
            >
                <v-icon left>mdi-currency-usd</v-icon>Financement
            </v-btn>
            <v-btn
                v-if="$vuetify.breakpoint.xs"
                large color="secondary" class="primary--text" block tile
                :href="'tel:' + $store.state.data.phone"
            >
                <v-icon left>mdi-phone</v-icon>{{ $store.state.data.phone }}
            </v-btn>
            <keep-alive>
                <router-view></router-view>
            </keep-alive>
        </v-main>

        <v-footer dark padless>
            <v-card flat tile class="primary white--text text-center" width="100%">
                <v-card-text>
                    <v-btn
                        class="mx-4 white--text" icon
                        :href="'https://facebook.com/' + $store.state.data.facebook"
                        target="_blank"
                    >
                        <v-icon size="24px">mdi-facebook</v-icon>
                    </v-btn>
                    <v-btn
                        class="mx-4 white--text" icon
                        :href="'https://m.me/' + $store.state.data.facebook"
                        target="_blank"
                    >
                        <v-icon size="24px">mdi-facebook-messenger</v-icon>
                    </v-btn>
                </v-card-text>
                <v-card-text class="white--text pt-0 text-center">
                    <v-row dense>
                        <v-col cols="12" sm="4">
                            <v-hover v-slot="{ hover }">
                                <a href="https://cdmecono.com" target="_blank">
                                    <v-img
                                        height="100"
                                        :src="require('./../assets/img/partners/cdmecono.svg')"
                                        contain
                                        :class="{ 'partner': !hover }"
                                    ></v-img>
                                </a>
                            </v-hover>
                        </v-col>
                        <v-col cols="6" sm="4">
                            <v-hover v-slot="{ hover }">
                                <a href="https://napaautocare.ca" target="_blank">
                                    <v-img
                                        height="100"
                                        :src="require('./../assets/img/partners/napa-autocare.png')"
                                        contain
                                        :class="{ 'partner': !hover }"
                                    ></v-img>
                                </a>
                            </v-hover>
                        </v-col>
                        <v-col cols="6" sm="4">
                            <v-hover v-slot="{ hover }">
                                <a href="https://pasuper.com" target="_blank">
                                    <v-img
                                        height="100"
                                        :src="require('./../assets/img/partners/pieces-auto-super.png')"
                                        contain
                                        :class="{ 'partner': !hover }"
                                    ></v-img>
                                </a>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="white--text">
                    &copy; {{ new Date().getFullYear() }} <strong>{{ _env.VUE_APP_COPYRIGHT }}</strong> – 
                    Développé par <strong><a :href="_env.VUE_APP_AUTHOR_LINK" class="author" target="_blank">{{ _env.VUE_APP_AUTHOR }}</a></strong>
                </v-card-text>
            </v-card>
        </v-footer>
    </v-app>
</template>

<script>
    // Router
    import Vue from "vue";
    import VueRouter from "vue-router";
    import routes from "./routes";
    Vue.use(VueRouter);
    const router = new VueRouter({ routes: routes, mode: "history" });
    router.beforeEach((to, from, next) => {
        document.title = to.meta.title + " – " + process.env.VUE_APP_NAME;
        next();
    });

    // Imports
    import NavigationElement from "./elements/Navigation";
    import SidebarElement from "./elements/Sidebar";
    import SearchElement from "./elements/Search";
    import SnackbarElement from "./elements/Snackbar";
    import LoginDialog from "./dialogs/Login";
    import SettingsDialog from "./dialogs/Settings";
    import FundingDialog from "./dialogs/Funding";

    // Module
    export default {
        name: "app",
        router: router,
        components: {
            NavigationElement,
            SidebarElement,
            SearchElement,
            SnackbarElement,
            LoginDialog,
            SettingsDialog,
            FundingDialog
        },
        data: () => ({
            drawer: false,
            fetching: false
        }),
        methods: {
            fetch() {
                this.fetching = true;
                let snack = null;
                this._apiRequest("data/get").then(response => {
                    if(!response.error) {
                        this.$store.commit({ type: "setData", data: response.data });
                        if(response.user) {
                            this.$store.commit({ type: "setUser", user: response.user });
                            snack = {
                                text: "Bonjour " + response.user.name + ", vous êtes connecté!",
                                icon: "mdi-check",
                                color: "success"
                            };
                        }
                    } else {
                        snack = {
                            text: response.error,
                            icon: "mdi-alert-circle",
                            color: "error"
                        };
                    }
                }).finally(() => {
                    this.fetching = false;
                    this.$root.snackbar = this.$refs.snackbar;
                    this.$root.loginDialog = this.$refs.loginDialog;
                    this.$root.settingsDialog = this.$refs.settingsDialog;
                    this.$root.fundingDialog = this.$refs.fundingDialog;
                    if(snack) {
                        this.$root.snackbar.show(snack);
                    }
                });
            },
            
        },
        mounted() {
            this.fetch();
        }
    };
</script>

<style scoped>
    .author {
        text-decoration: none;
        color: #0090ff;
    }

    .partner {
        opacity: 0.7;
    }
</style>