// Import views
import HomeView from "./views/Home";
import InventoryView from "./views/Inventory";
import ItemView from "./views/Item";
import ContactView from "./views/Contact";

// Routes
export default [
    {
        path: "/home", name: "home", component: HomeView,
        meta: {
            icon: "mdi-home",
            title: "Accueil",
            /*metaTags: [
                { name: "description", content: "" }
            ]*/
        }
    },
    {
        path: "/inventory/:query?", name: "inventory", component: InventoryView,
        meta: {
            icon: "mdi-car-multiple",
            title: "Inventaire"
        }
    },
    {
        path: "/inventory/item/:id?", name: "item", component: ItemView,
        meta: {
            icon: "mdi-car",
            title: "Inventaire",
            hidden: true
        }
    },
    {
        path: "/contact", name: "contact", component: ContactView,
        meta: {
            icon: "mdi-message",
            title: "Contact"
        }
    },
    { path: "*", redirect: "home" }
];