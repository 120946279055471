<template>
    <div>
        <template v-for="(route, i) in $router.options.routes">
            <v-btn
                v-if="route.meta && !route.meta.hidden"
                large text :class="{ 'ml-2': (i > 0) }"
                :key="i"
                :to="route"
                active-class="secondary--text"
            >
                <v-icon left>{{ route.meta.icon }}</v-icon>{{ route.meta.title }}
            </v-btn>
        </template>
    </div>
</template>

<script>
    // Module
    export default {
        name: "navigation-element",
        data: () => ({})
    };
</script>