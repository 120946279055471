<template>
    <div>
        <v-parallax
            dark
            :src="$store.state.data.home_header_img ? $store.state.data.home_header_img : require('./../../assets/img/temp/test (5).jpg')"
            height="500"
            :key="$store.state.data.home_header_img"
        >
            <v-container fluid fill-height align-center justify-center>
                <v-row>
                    <v-col cols="12" class="text-center">
                        <h1 class="display-2 mb-4">{{ $store.state.data.title }}</h1>
                        <h4 class="subheading">{{ $store.state.data.subtitle }}</h4>
                    </v-col>
                </v-row>
            </v-container>
        </v-parallax>
        <div class="text-center pa-3 headline grey darken-4 white--text">
            <v-icon left color="white">mdi-car-side</v-icon>
            Nouveautés dans notre inventaire
            <v-icon right color="white">mdi-car-side</v-icon>
        </div>
        <v-container fluid>
            <div class="d-flex" v-if="!fetching">
                <v-slide-group multiple :show-arrows="$vuetify.breakpoint.smAndUp">
                    <template v-slot:prev>
                        <v-icon x-large>mdi-chevron-left</v-icon>
                    </template>
                    <template v-slot:next>
                        <v-icon x-large>mdi-chevron-right</v-icon>
                    </template>
                    <v-slide-item v-for="(item, i) in items" :key="i">
                        <router-link :to="{ name: 'item', params: { id: item.id } }" class="text-decoration-none">
                            <v-card width="340" class="mx-2 grey" dark>
                                <v-img
                                    height="250"
                                    :src="!!item.photo ? '/uploads/' + item.photo : require('./../../assets/img/coming-soon.png')"
                                >
                                    <v-container
                                        v-if="!item.sold_at"
                                        fluid fill-height align-start justify-end
                                        style="position: absolute;"
                                    >
                                        <v-chip
                                            label color="primary" dark
                                            class="title font-weight-bold secondary--text"
                                        >
                                            {{ item.price }} $
                                        </v-chip>
                                    </v-container>
                                    <v-container
                                        v-else
                                        fluid fill-height align-center justify-center
                                        style="position: absolute;"
                                    >
                                        <v-chip
                                            label x-large color="error" dark
                                            class="display-1 sold"
                                        >
                                            <v-icon left size="40">mdi-check</v-icon>VENDU
                                        </v-chip>
                                    </v-container>
                                    <v-container
                                        fluid fill-height align-end justify-end
                                        style="position: absolute;"
                                    >
                                        <v-chip
                                            label color="secondary" dark
                                            class="title font-weight-bold primary--text"
                                        >
                                            {{ item.kilometrage }} KM
                                        </v-chip>
                                    </v-container>
                                </v-img>
                                <div class="grey darken-4">
                                    <v-card-title>{{ item.brand }} {{ item.model }} {{ item.year }}</v-card-title>
                                    <v-card-subtitle>{{ item.model_description }}</v-card-subtitle>
                                    <v-card-text>
                                        <v-chip-group
                                            active-class="primary white--text"
                                            column
                                        >
                                            <v-chip small>{{ item.color }}</v-chip>
                                            <v-chip small>{{ item.kilometrage }} KM</v-chip>
                                            <v-chip small>{{ item.transmission }}</v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </div>
                            </v-card>
                        </router-link>
                    </v-slide-item>
                </v-slide-group>
            </div>
            <div class="text-center" v-else>
                <v-progress-circular
                    indeterminate
                    color="primary"
                    size="80"
                ></v-progress-circular>
            </div>
            <router-link :to="{ name: 'inventory' }" class="text-decoration-none">
                <v-btn block text color="primary" class="mt-3"><v-icon left>mdi-car-multiple</v-icon>Consulter notre inventaire</v-btn>
            </router-link>
        </v-container>
    </div>
</template>

<script>
    // Module
    export default {
        name: "home-view",
        data: () => ({
            fetching: false,
            items: []
        }),
        methods: {
            fetch() {
                //console.log("fetch");
                this.fetching = true;
                this._apiRequest("items/get-all", { latest: true }).then(response => {
                    if(!response.error) {
                        this.items = response.items;
                    } else {
                        this.$root.snackbar.show({
                            text: response.error,
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                    }
                }).finally(() => {
                    this.fetching = false;
                });
            }
        },
        activated() {
            if(this.items.length === 0) {
                this.fetch();
            }
        }
    };
</script>

<style scoped>
    /* touch hack */
    .v-slide-group__wrapper {
        touch-action: auto !important;
    }

    .sold {
        margin-top: 50px;
        transform-origin: 0 0;
        transform: rotate(-20deg);
        opacity: 0.7;
    }
</style>