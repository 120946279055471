<template>
    <v-dialog v-model="dialog" @input="dialog = false" max-width="400" :persistent="loading">
        <v-form ref="form" @submit.prevent @submit="login">
            <v-card>
                <v-toolbar flat color="primary" dark>
                    <v-toolbar-title>
                        <v-icon left>mdi-login</v-icon>Connexion
                    </v-toolbar-title>
                </v-toolbar>
                <v-container fluid>
                    <v-row dense>
                        <v-col cols="12">
                            <v-text-field
                                label="Nom d'utilisateur"
                                v-model="form.username"
                                :disabled="loading"
                                :rules="[ $data._rules.required ]"
                                ref="username"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                label="Mot de passe"
                                v-model="form.password"
                                type="password"
                                :disabled="loading"
                                :rules="[ $data._rules.required ]"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn text @click="dialog = false" :disabled="loading">Fermer</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" type="submit" :loading="loading">Se connecter</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    // Module
    export default {
        name: "login-dialog",
        props: {},
        data: () => ({
            dialog: false,
            loading: false,
            form: {
                username: null,
                password: null
            }
        }),
        methods: {
            open() {
                this.dialog = true;
                if(this.$refs.form) {
                    this.$refs.form.resetValidation();
                }
                setTimeout(() => {
                    if(this.$refs.username) {
                        this.$refs.username.focus();
                    }
                }, 300);
            },
            login() {
                if(this.$refs && this.$refs.form && this.$refs.form.validate()) {
                    this.loading = true;
                    this._apiRequest("users/login", {
                        username: this.form.username,
                        password: this.form.password
                    }).then(response => {
                        if(!response.error) {
                            this.$store.commit({ type: "setUser", user: response.user });

                            this.$root.snackbar.show({
                                text: "Bonjour " + response.user.name + ", vous êtes connecté!",
                                icon: "mdi-check",
                                color: "success"
                            });

                            this.form.username = null;
                            this.form.password = null;

                            this.dialog = false;
                        } else {
                            this.$root.snackbar.show({
                                text: response.error,
                                icon: "mdi-alert-circle",
                                color: "error"
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            },
            logout() {
                this._apiRequest("users/logout").then(response => {
                    if(!response.error) {
                        this.$store.commit({ type: "setUser", user: null });
                        this.$root.snackbar.show({
                            text: "Vous vous êtes déconnecté avec succès!",
                            icon: "mdi-check",
                            color: "success"
                        });
                    } else {
                        this.$root.snackbar.show({
                            text: response.error,
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                    }
                });
            }
        }
    };
</script>