<template>
    <div>
        <v-img
            :src="$store.state.data.page_header_img ? $store.state.data.page_header_img : require('./../../assets/img/temp/test (5).jpg')"
            height="250"
            dark
        >
            <v-container fluid fill-height align-center justify-center>
                <h1 class="display-1">
                    <v-icon large left>{{ icon }}</v-icon>{{ title }}
                </h1>
            </v-container>
        </v-img>
        <v-divider></v-divider>
        <div id="content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    // Module
    export default {
        name: "content-layout",
        props: {
            icon: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: true
            }
        }
    };
</script>

<style scoped>
    #content {
        position: relative;
    }
</style>