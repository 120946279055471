<template>
    <ContentLayout icon="mdi-message" title="Contactez-nous">
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="7" lg="8" class="primary--text">
                    <h1 class="title pb-4">Nous joindre</h1>
                    <v-btn
                        x-large outlined color="primary" block class="mb-3"
                        href="tel:5143701122"
                        target="_blank"
                    >
                        <v-icon left>mdi-phone</v-icon>{{ $store.state.data.phone }}
                    </v-btn>
                    <v-btn
                        x-large outlined color="primary" block class="mb-3"
                        href="mailto:info@autoecono.ca"
                        target="_blank"
                    >
                        <v-icon left>mdi-email</v-icon>{{ $store.state.data.email }}
                    </v-btn>
                    <v-btn
                        x-large outlined color="blue darken-2" block class="mb-4"
                        :href="'https://facebook.com/' + $store.state.data.facebook"
                        target="_blank"
                    >
                        <v-icon left>mdi-facebook</v-icon>Facebook
                    </v-btn>
                    <v-btn
                        x-large outlined color="blue" block class="mb-4"
                        :href="'https://m.me/' + $store.state.data.facebook"
                        target="_blank"
                    >
                        <v-icon left>mdi-facebook-messenger</v-icon>Messenger
                    </v-btn>

                    <h1 class="title pb-4">Notre adresse</h1>
                    <v-btn
                        large color="primary" block class="mb-4" text
                        :href="'https://www.google.com/maps/dir/?api=1&destination=' + $store.state.data.address"
                        target="_blank"
                    >
                        <v-icon left>mdi-map-marker</v-icon> {{ $store.state.data.address }}
                    </v-btn>
                </v-col>
                <v-col cols="12" md="5" lg="4">
                    <v-card dark color="dark" class="mb-3">
                        <v-card-title class="primary secondary--text"><v-icon left color="secondary">mdi-clock</v-icon>Heures d'ouverture</v-card-title>
                        <div class="py-4">
                            <v-row v-for="(item, i) in openingHours" :key="i" class="px-4 py-1">
                                <v-col class="font-weight-medium" :class="{ 'success--text': i == dow && !!item, 'error--text': i == dow && !!item }">
                                    <v-icon size="18" left :color="i == dow ? (!!item ? 'success' : 'error') : null">{{ i == dow ? "mdi-circle" : "mdi-circle-outline" }}</v-icon>
                                    {{ days[i] }}
                                </v-col>
                                <v-col class="font-weight-medium text-right" :class="{ 'success--text': i == dow && !!item, 'error--text': i == dow && !item }">
                                    {{ !!item ? item : "FERMÉ" }}
                                </v-col>
                            </v-row>
                            <div class="text-center" v-if="openingHours.fetching">
                                <v-progress-circular
                                    indeterminate
                                    color="secondary"
                                    size="80"
                                ></v-progress-circular>
                            </div>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </ContentLayout>
</template>

<script>
    // Imports
    import ContentLayout from "./../layouts/Content";

    // Module
    export default {
        name: "contact-view",
        components: {
            ContentLayout
        },
        data: () => ({
            /*openingHours: {
                fetching: false,
                items: []
            }*/
            days: [
                "Dimanche",
                "Lundi",
                "Mardi",
                "Mercredi",
                "Jeudi",
                "Vendredi",
                "Samedi"
            ]
        }),
        computed: {
            dow() {
                return new Date().getDay();
            },
            openingHours() {
                return !!this.$store.state.data && !!this.$store.state.data.opening_hours ? 
                    this.$store.state.data.opening_hours.split(";") : [];
            }
        },
        /*methods: {
            fetchOpeningHours() {
                this.openingHours.fetching = true;
                this._apiRequest("data/get-opening-hours").then(response => {
                    this.openingHours.items = response.data;
                }).finally(() => {
                    this.openingHours.fetching = false;
                });
            }
        },
        created() {
            this.fetchOpeningHours();
        }*/
    };
</script>
