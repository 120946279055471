<template>
    <ContentLayout icon="mdi-car-multiple" title="Notre inventaire">
        <router-link :to="{ name: 'item' }" v-if="$store.state.user">
            <v-btn color="primary" fab dark absolute top right>
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </router-link>
        <v-container fluid>
            <v-row dense v-if="!fetching && loaded">
                <template v-if="filteredItems.length > 0">
                    <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, i) in filteredItems" :key="i">
                        <router-link :to="{ name: 'item', params: { id: item.id } }" class="text-decoration-none">
                            <v-card class="grey" dark>
                                <v-img
                                    height="250"
                                    :src="!!item.photo ? '/uploads/' + item.photo : require('./../../assets/img/coming-soon.png')"
                                >
                                    <v-container
                                        v-if="!item.sold_at"
                                        fluid fill-height align-start justify-end
                                        style="position: absolute;"
                                    >
                                        <v-chip
                                            label color="primary" dark
                                            class="title font-weight-bold secondary--text"
                                        >
                                            {{ item.price }} $
                                        </v-chip>
                                    </v-container>
                                    <v-container
                                        v-else
                                        fluid fill-height align-center justify-center
                                        style="position: absolute;"
                                    >
                                        <v-chip
                                            label x-large color="error" dark
                                            class="display-1 sold"
                                        >
                                            <v-icon left size="40">mdi-check</v-icon>VENDU
                                        </v-chip>
                                    </v-container>
                                    <v-container
                                        fluid fill-height align-end justify-end
                                        style="position: absolute;"
                                    >
                                        <v-chip
                                            label color="secondary" dark
                                            class="title font-weight-bold primary--text"
                                        >
                                            {{ item.kilometrage }} KM
                                        </v-chip>
                                    </v-container>
                                </v-img>
                                <div class="grey darken-4">
                                    <v-card-title>{{ item.brand }} {{ item.model }} {{ item.year }}</v-card-title>
                                    <v-card-subtitle>{{ item.model_description }}</v-card-subtitle>
                                    <v-card-text>
                                        <v-chip-group
                                            active-class="primary white--text"
                                            column
                                        >
                                            <v-chip small>{{ item.color }}</v-chip>
                                            <v-chip small>{{ item.kilometrage }} KM</v-chip>
                                            <v-chip small>{{ item.transmission }}</v-chip>
                                        </v-chip-group>
                                    </v-card-text>
                                </div>
                            </v-card>
                        </router-link>
                    </v-col>
                </template>
                <template v-else>
                    <v-col cols="12" sm="6" md="8" lg="10">
                        <v-alert dense outlined type="warning" v-if="query">
                            Aucun résultat pour "{{ query }}".
                        </v-alert>
                        <v-alert dense outlined type="info" v-else>
                            Aucune entrée trouvée.
                        </v-alert>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" lg="2" v-if="query">
                        <router-link :to="{ name: 'inventory' }" class="text-decoration-none">
                            <v-btn block text color="primary"><v-icon left>mdi-car-multiple</v-icon>Afficher l'inventaire</v-btn>
                        </router-link>
                    </v-col>
                </template>
            </v-row>
            <div class="text-center" v-else>
                <v-progress-circular
                    indeterminate
                    color="primary"
                    size="80"
                ></v-progress-circular>
            </div>
        </v-container>
    </ContentLayout>
</template>

<script>
    // Imports
    import ContentLayout from "./../layouts/Content";

    // Module
    export default {
        name: "inventory-view",
        components: {
            ContentLayout
        },
        data: () => ({
            fetching: false,
            items: []
        }),
        computed: {
            query() {
                return this.$route.params.query ? decodeURI(this.$route.params.query) : null;
            },
            filteredItems() {
                return this.items.filter(item => this.query ? JSON.stringify(item).toLowerCase().indexOf(this.query) !== -1 : true);
            },
            loaded() {
                return this.items.find(item => item.loaded == false) ? false : true;
            }
        },
        methods: {
            fetch() {
                //console.log("fetch");
                this.fetching = true;
                this._apiRequest("items/get-all").then(response => {
                    if(!response.error) {
                        this.items = response.items;
                    } else {
                        this.$root.snackbar.show({
                            text: response.error,
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                    }
                }).finally(() => {
                    this.fetching = false;
                });
            }
        },
        /*beforeRouteUpdate(to, from, next) {
            this.fetch(to.params.query ? decodeURI(to.params.query) : null);
            next(true);
        },*/
        activated() {
            //this.fetch(this.$route.params.query ? decodeURI(this.$route.params.query) : null);
            if(this.items.length === 0) {
                this.fetch();
            }
        }
    };
</script>

<style scoped>
    .sold {
        margin-top: 50px;
        transform-origin: 0 0;
        transform: rotate(-20deg);
        opacity: 0.7;
    }
</style>