<template>
    <v-dialog v-model="dialog" @input="dialog = false" max-width="800" :persistent="loading">
        <v-form ref="form" @submit.prevent :lazy-validation="false">
            <v-card>
                <v-toolbar flat color="primary" dark>
                    <v-toolbar-title>
                        <v-icon left>mdi-currency-usd</v-icon>Financement
                    </v-toolbar-title>
                </v-toolbar>
                <v-stepper v-model="step" class="elevation-0">
                    <v-stepper-header>
                        <v-stepper-step step="1">Informations personnelles</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2">Revenu et emploi</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3">Sécurité</v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content step="1">
                            <v-row dense>
                                <v-col cols="6" md="4">
                                    <v-text-field
                                        label="Prénom"
                                        v-model="form.first_name"
                                        :rules="[ $data._rules.required ]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" md="4">
                                    <v-text-field
                                        label="Nom"
                                        v-model="form.last_name"
                                        :rules="[ $data._rules.required ]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field
                                        label="Date de naissance"
                                        v-model="form.birthdate"
                                        :rules="[ $data._rules.required ]"
                                        v-mask="'####-##-##'"
                                        hint="AAAA-MM-JJ"
                                        persistent-hint
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        label="Courriel"
                                        v-model="form.email"
                                        :rules="[ $data._rules.required, $data._rules.email ]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        label="# Téléphone"
                                        v-model="form.phone"
                                        :rules="[ $data._rules.required ]"
                                        v-mask="'###-###-####'"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Adresse complète"
                                        v-model="form.address"
                                        :rules="[ $data._rules.required ]"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="grey" elevation="4">
                                        <v-img
                                            v-if="form.permit_photo"
                                            height="300"
                                            :src="form.permit_photo"
                                            class="img"
                                        >
                                            <v-container fluid fill-height align-start justify-center>
                                                <v-chip label color="primary" class="title font-weight-bold" dark>Permis de conduire</v-chip>
                                            </v-container>
                                        </v-img>
                                        <div class="primary">
                                            <input type="file" v-show="false" ref="permitPhoto" accept=".jpg,.jpeg" @change="loadPermitPhoto" />
                                            <v-btn block text dark @click="$refs.permitPhoto.click();"><v-icon left>mdi-image-plus</v-icon>Joindre un permis de conduire (photo)</v-btn>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Numéro d'assurance sociale (N.A.S.)"
                                        v-model="form.nas"
                                        v-mask="'### ### ###'"
                                        hint="* Facultatif - Aide a être approuvé rapidement"
                                        persistent-hint
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                            <v-row dense>
                                <v-col cols="6" md="6">
                                    <v-text-field
                                        label="Revenu personnel"
                                        v-model="form.personal_income"
                                        :rules="[ $data._rules.required, $data._rules.number ]"
                                        maxlength="32"
                                    >
                                        <template v-slot:append>$</template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6" md="6">
                                    <v-text-field
                                        label="Revenu du ménage"
                                        v-model="form.household_income"
                                        :rules="[ $data._rules.required, $data._rules.number ]"
                                        maxlength="32"
                                    >
                                        <template v-slot:append>$</template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6" md="6">
                                    <v-select
                                        label="Type de propriété"
                                        v-model="form.property_type"
                                        :items="propertyTypes"
                                        :rules="[ $data._rules.required ]"
                                    ></v-select>
                                </v-col>
                                <v-col cols="6" md="6">
                                    <v-text-field
                                        label="Frais de logement"
                                        v-model="form.housing_costs"
                                        :rules="[ $data._rules.required, $data._rules.number ]"
                                        maxlength="32"
                                    >
                                        <template v-slot:append>$</template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-select
                                        label="Profession"
                                        v-model="form.profession"
                                        :items="professions"
                                        :rules="[ $data._rules.required ]"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Poste"
                                        v-model="form.position"
                                        :rules="[ $data._rules.required ]"
                                        maxlength="128"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        label="Nom de l'employeur"
                                        v-model="form.employer_name"
                                        :rules="[ $data._rules.required ]"
                                        maxlength="128"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        label="# Téléphone de l'employeur"
                                        v-model="form.employer_phone"
                                        :rules="[ $data._rules.required ]"
                                        v-mask="'###-###-#### #####'"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                            <v-alert dense outlined type="info">
                                Les renseignements suivants faciliteront l’authentification future. 
                                La réponse à ces questions sera requise quand vous vous connecterez à votre compte Flexiti. 
                                <!-- Le mot d’identification personnelle sera utilisé pour vous aider à vous connecter à votre compte Flexiti et faire des achats futurs. 
                                Il ne peut contenir que des lettres et des chiffres, et n’est pas sensible à la casse. -->
                            </v-alert>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-select
                                        label="Question secrète"
                                        v-model="form.question"
                                        :items="questions"
                                        :rules="[ $data._rules.required ]"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Réponse à la question secrète"
                                        v-model="form.answer"
                                        :rules="[ $data._rules.required ]"
                                        maxlength="128"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn text @click="dialog = false" :disabled="loading">Fermer</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="step--" v-if="step > 1"><v-icon>mdi-arrow-left</v-icon></v-btn>
                    <v-btn text color="primary" @click="step++" v-if="step < 3">Suivant</v-btn>
                    <v-btn text color="primary" type="submit" @click="submit" :loading="loading" v-if="step == 3">Soumettre</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    // Module
    export default {
        name: "funding-dialog",
        props: {},
        data: () => ({
            dialog: false,
            loading: false,
            step: 1,
            form: {
                // General section
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                birthdate: null,
                address: null,
                permit_photo: null,

                // Personal section
                personal_income: null,
                household_income: null,
                property_type: null,
                housing_costs: null,
                profession: null,
                position: null,
                employer_name: null,
                employer_phone: null,
                nas: null,

                // Security section
                question: null,
                answer: null,

                location: null
            },
            defaults: null,

            professions: [
                "Employé à plein temps",
                "Employé à temps partiel",
                "Travailleur autonome",
                "Retraité",
                "Étudiant",
                "Personne au foyer",
                "Personne vivant avec une invalidité",
                "Travailleur saisonnier",
                "Sans travail avec revenu",
                "Sans travail sans revenu",
                "Autre"
            ],
            questions: [
                "Quel était le prénom de votre meilleur(e) ami(e) d'enfance?",
                "Quel pays représente pour vous une destination de rêve?",
                "Quel est le premier concert auquel vous avez assisté?",
                "Quel est le nom de la rue sur laquelle vous avez grandi?",
                "Quel était le prénom de votre professeur de première année?",
                "Quel est votre film préféré?"
            ],
            propertyTypes: [
                "Location",
                "Propriétaire",
                "Vie chez les parents",
                "Autre"
            ]
        }),
        methods: {
            open() {
                this.dialog = true;
                if(this.$refs.form) {
                    this.$refs.form.resetValidation();
                }
            },
            submit() {
                if(this.$refs && this.$refs.form && this.$refs.form.validate() && this.form.permit_photo) {
                    this.form.location = window.location.href;

                    this.loading = true;
                    this._apiRequest("forms/funding", this.form).then(response => {
                        if(!response.error) {
                            this.$root.snackbar.show({
                                text: "Votre formulaire de financement a été soumis avec succès!",
                                icon: "mdi-check",
                                color: "success"
                            });
                            /*this.dialog = false;
                            this.form = JSON.parse(this.defaults);
                            this.step = 1;*/
                        } else {
                            this.$root.snackbar.show({
                                text: response.error,
                                icon: "mdi-alert-circle",
                                color: "error"
                            });
                        }
                    }).finally(() => {
                        this.loading = false;
                    });
                } else {
                    this.$root.snackbar.show({
                        text: "Vous devez remplir tous les champs obligatoires.",
                        icon: "mdi-alert-circle",
                        color: "error"
                    });
                }
            },

            loadPermitPhoto() {
                let element = this.$refs.permitPhoto;
                let file = element && element.files && element.files.length > 0 ? element.files[0] : null;
                if(file) {
                    let img = new Image();
                    img.onload = () => {
                        // Resize
                        let canvas = document.createElement("canvas");
                        let ctx = canvas.getContext("2d");
                        let scale = 800 / img.width;
                        canvas.width = img.width * scale;
                        canvas.height = img.height * scale;
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                        let data = canvas.toDataURL(file.type, 0.9);
                        
                        // Set
                        this.form.permit_photo = data;
                    }
                    img.src = window.URL.createObjectURL(file);
                }
            }
        },
        created() {
            this.defaults = JSON.stringify(this.form);
        }
    };
</script>