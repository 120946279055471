import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    //iconfont: "mdi",
    theme: {
        themes: {
            light: {
                primary: "#083793",
                secondary: "#f8b21f",
                /*accent: "#82B1FF",
                error: "#FF5252",
                info: "#2196F3",
                success: "#4CAF50",
                warning: "#FFC107"*/
            }
        }
    }
});
