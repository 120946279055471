import Vue from "vue";
import vuetify from "./plugins/vuetify";
import { store } from "./plugins/store";
import "./plugins/mask";
import "./plugins/mixins";

import App from "./components/App.vue";

Vue.config.productionTip = false;

new Vue({
    vuetify,
    store: store,
    render: h => h(App)
}).$mount("#app");

// process.env.<>