import Vue from "vue";

Vue.mixin({
    data: () => ({
        _rules: {
            required: value => !!value || 
                "Ce champs est obligatoire.",
            number: value => {
                value = typeof value !== undefined && value != null ? (isNaN(value) ? value : value.toString()) : "";
                return !value || (!isNaN(value) && parseFloat(value) == value && !isNaN(parseInt(value, 10))) || 
                    "Ce champs doit être un nombre valide.";
            },
            email: value => {
                value = typeof value !== undefined && value != null ? (isNaN(value) ? value : value.toString()) : "";
                return !value || (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) || 
                    "Ce champs doit être un courriel valide.";
            },
            time: value => {
                value = typeof value !== undefined && value != null ? (isNaN(value) ? value : value.toString()) : "";
                return !value || (value.length === 5 && 
                    value.split(":").length === 2 && 
                    (parseInt(value.split(":")[0]) >= 0 && parseInt(value.split(":")[0]) <= 23) && 
                    (parseInt(value.split(":")[1]) >= 0 && parseInt(value.split(":")[1]) <= 59)) || 
                    "Ce champs doit être une heure valide.";
            },
            link: value => {
                value = typeof value !== undefined && value != null ? (isNaN(value) ? value : value.toString()) : "";
                return !value || (new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i').test(value)) || 
                    "Ce champs doit être un lien URL valide.";
            },
        }
    }),
    computed: {
        _env() {
            return process.env;
        }
    },
    methods: {
        _getDate(displayTime = false, timeSeparator = " ") {
            let offset = (new Date()).getTimezoneOffset() * 1000 * 60; // ms to hour
            let date = (new Date(Date.now() - offset)).toISOString().slice(0, -1);
            return displayTime ? date.substr(0, 10) + timeSeparator + date.substr(11, 8) : date.substr(0, 10);
        },
        _getUpdatedDate(date, part, value) {
            date = new Date(date);
            switch(part) {
                case "year":
                    date.setFullYear(date.getFullYear() + value);
                    break;
                case "month":
                    date.setMonth(date.getMonth() + value);
                    break;
                case "day":
                    date.setDate(date.getDate() + value);
                    break;
            }
            return date.toISOString().substr(0, 10);
        },
        _getString(value) {
            return typeof value !== undefined && value != null ? (isNaN(value) ? value : value.toString()) : "";
        },
        _getBase64Image(img) {
            let canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            let ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            let dataURL = canvas.toDataURL("image/png");
            return dataURL; // dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
        },
        _getBase64ImageFromURL(url) { // async FUNCTION() await this.FUNCTION
            return new Promise((resolve, reject) => {
                let img = new Image();
                img.setAttribute("crossOrigin", "anonymous");

                img.onload = () => {
                    let canvas = document.createElement("canvas");
                    canvas.width = img.width;
                    canvas.height = img.height;

                    let ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0);

                    let dataURL = canvas.toDataURL("image/png");

                    resolve(dataURL);
                };

                img.onerror = error => {
                    reject(error);
                };

                img.src = url;
            });
        },

        _apiRequest(service, data = null) {
            return new Promise(resolve => {
                fetch(process.env.VUE_APP_API, {
                    method: "post",
                    headers: { "Content-Type": "application/json;charset=utf-8" },
                    body: JSON.stringify({
                        service: service,
                        data: data
                    }),
                    mode: process.env.VUE_APP_ENV == "DEV" ? "cors" : "same-origin",
                    credentials: process.env.VUE_APP_ENV == "DEV" ? "include" : "same-origin"
                }).then(response => {
                    if(response.ok) {
                        response.json().then(r => {
                            resolve(r);
                        }).catch(() => {
                            resolve({ error: "Il y a eu une erreur avec le serveur de données. (1)" });
                        });
                    } else {
                        resolve({ error: "Il y a eu une erreur avec le serveur de données. (2)" });
                    }
                }).catch(() => {
                    resolve({ error: "Il y a eu une erreur avec le serveur de données. (3)" });
                });
            });
        }
    }
});