<template>
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar dark color="primary">
                <v-toolbar-title><v-icon left>mdi-cog</v-icon>Paramètres</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>
            <v-tabs v-model="tab" show-arrows background-color="grey lighten-1" active-class="grey lighten-2">
                <v-tab ripple><v-icon left>mdi-database-cog</v-icon>Données</v-tab>
                <v-tab ripple @click="fetchUsers"><v-icon left>mdi-account-multiple</v-icon>Utilisateurs</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" touchless>
                <!-- Data -->
                <v-tab-item>
                    <v-form ref="dataForm" @submit="saveData" @submit.prevent>
                        <v-container fluid>
                            <v-row dense>
                                <v-col cols="6" md="6" lg="2">
                                    <v-text-field
                                        label="# Téléphone"
                                        v-model="data.form.phone"
                                        :rules="[ $data._rules.required ]"
                                        maxlength="64"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6" md="6" lg="2">
                                    <v-text-field
                                        label="Courriel"
                                        v-model="data.form.email"
                                        :rules="[ $data._rules.required, $data._rules.email ]"
                                        maxlength="256"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="4">
                                    <v-text-field
                                        label="Nom d'utilisateur Facebook"
                                        v-model="data.form.facebook"
                                        :rules="[ $data._rules.required ]"
                                        maxlength="256"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6" lg="4">
                                    <v-text-field
                                        label="Adresse"
                                        v-model="data.form.address"
                                        :rules="[ $data._rules.required ]"
                                        maxlength="256"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="5">
                                    <v-text-field
                                        label="Titre"
                                        v-model="data.form.title"
                                        :rules="[ $data._rules.required ]"
                                        maxlength="64"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="7">
                                    <v-text-field
                                        label="Sous-titre"
                                        v-model="data.form.subtitle"
                                        :rules="[ $data._rules.required ]"
                                        maxlength="128"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-card class="grey" elevation="4">
                                        <v-img
                                            height="300"
                                            :src="data.form.home_header_img ? data.form.home_header_img : require('./../../assets/img/logo-dark.svg')"
                                            class="img"
                                        >
                                            <v-container fluid fill-height align-start justify-center>
                                                <v-chip label color="primary" class="title font-weight-bold" dark>En-tête principale</v-chip>
                                            </v-container>
                                        </v-img>
                                        <div class="primary">
                                            <input type="file" v-show="false" ref="homeImage" accept=".jpg,.jpeg" @change="loadHomeImage" />
                                            <v-btn block text dark @click="$refs.homeImage.click();" :disabled="data.saving"><v-icon left>mdi-image-plus</v-icon>Ajouter une image</v-btn>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-card class="grey" elevation="4">
                                        <v-img
                                            height="300"
                                            :src="data.form.page_header_img ? data.form.page_header_img : require('./../../assets/img/logo-dark.svg')"
                                        >
                                            <v-container fluid fill-height align-start justify-center>
                                                <v-chip label color="primary" class="title font-weight-bold" dark>En-tête des pages</v-chip>
                                            </v-container>
                                        </v-img>
                                        <div class="primary">
                                            <input type="file" v-show="false" ref="pageImage" accept=".jpg,.jpeg" @change="loadPageImage" />
                                            <v-btn block text dark @click="$refs.pageImage.click();" :disabled="data.saving"><v-icon left>mdi-image-plus</v-icon>Ajouter une image</v-btn>
                                        </div>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-card class="primary" dark>
                                        <v-card-title>
                                            Heures d'ouverture
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row dense>
                                                <v-col
                                                    v-for="(item, i) in data.openingHours" :key="i"
                                                    cols="6" sm="4" md="3"
                                                >
                                                    <v-text-field
                                                        :label="item.text"
                                                        v-model="item.value"
                                                        outlined hide-details dense clearable
                                                        maxlength="20"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="text-right">
                                    <v-btn color="primary" type="submit" :loading="data.saving">Sauvegarder</v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-tab-item>
                <!-- /Data -->
                <!-- Users -->
                <v-tab-item>
                    <v-dialog v-model="users.dialog" :persistent="users.saving" width="500">
                        <v-form ref="userForm" @submit="saveUser" @submit.prevent>
                            <v-card>
                                <v-toolbar flat color="primary" dark>
                                    <v-toolbar-title>
                                        <v-icon left>{{ users.form.id ? "mdi-pencil" : "mdi-account-plus" }}</v-icon>{{ users.form.id ? "Mise à jour d'un utilisateur" : "Ajout d'un utilisateur" }}
                                    </v-toolbar-title>
                                </v-toolbar>
                                <v-container fluid>
                                    <v-row dense>
                                        <v-col cols="12" v-if="users.form.id">
                                            <v-text-field
                                                label="ID"
                                                v-model="users.form.id"
                                                disabled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                label="Nom"
                                                v-model="users.form.name"
                                                :rules="[ $data._rules.required ]"
                                                maxlength="64"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                label="Nom d'utilisateur"
                                                v-model="users.form.username"
                                                :rules="[ $data._rules.required ]"
                                                maxlength="64"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field
                                                label="Mot de passe"
                                                v-model="users.form.password"
                                                type="password"
                                                :rules="!users.form.id ? [ $data._rules.required ] : []"
                                                maxlength="32"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea
                                                label="Description"
                                                v-model="users.form.description"
                                                rows="3"
                                                auto-grow
                                            ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="users.dialog = false" :disabled="users.saving">Fermer</v-btn>
                                    <v-btn text color="primary" type="submit" :loading="users.saving">Sauvegarder</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-form>
                    </v-dialog>
                    <v-btn block tile color="primary" @click="openUser()"><v-icon left>mdi-account-plus</v-icon>Ajouter un utilisateur</v-btn>
                    <v-data-table
                        :loading="users.fetching"
                        :headers="users.headers"
                        :items="users.items"
                        sort-by="id"
                    >
                        <template v-slot:[`item.is_active`]="{ item }">
                            <v-chip small :color="item.is_active == 1 ? 'success' : 'warning'">{{ item.is_active == 1 ? "Actif" : "Inactif" }}</v-chip>
                        </template>
                        <template v-slot:[`item.is_admin`]="{ item }">
                            <v-chip small :color="item.is_admin == 1 ? 'primary' : 'default'">{{ item.is_admin == 1 ? "Administrateur" : "Utilisateur" }}</v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon small color="warning" @click="updateUserStatus(item)" :disabled="item.is_admin == 1 ? true : false"><v-icon small>mdi-power</v-icon></v-btn>
                                </template>
                                {{ item.is_active == 1 ? "Désactiver" : "Activé" }} l'utilisateur
                            </v-tooltip>
                            <v-btn depressed small @click="openUser(item)"><v-icon left>mdi-pencil</v-icon>Modifier</v-btn>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon small color="error" @click="deleteUser(item)" :disabled="item.is_admin == 1 ? true : false"><v-icon small>mdi-delete</v-icon></v-btn>
                                </template>
                                Supprimer l'utilisateur
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-tab-item>
                <!-- /Users -->
            </v-tabs-items>
        </v-card>
    </v-dialog>
</template>

<script>
    // Module
    export default {
        name: "settings-element",
        data: () => ({
            dialog: false,
            tab: 0,
            data: {
                //fetching: false,
                saving: false,
                form: {
                    phone: null,
                    email: null,
                    facebook: null,
                    address: null,
                    title: null,
                    subtitle: null,
                    home_header_img: null,
                    page_header_img: null,
                    opening_hours: null
                },
                openingHours: [
                    {
                        text: "Dimanche",
                        value: null
                    },
                    {
                        text: "Lundi",
                        value: null
                    },
                    {
                        text: "Mardi",
                        value: null
                    },
                    {
                        text: "Mercredi",
                        value: null
                    },
                    {
                        text: "Jeudi",
                        value: null
                    },
                    {
                        text: "Vendredi",
                        value: null
                    },
                    {
                        text: "Samedi",
                        value: null
                    }
                ]
            },
            users: {
                fetching: false,
                headers: [
                    { text: "ID", value: "id" },
                    { text: "Date de création", value: "created_at" },
                    { text: "Nom", value: "name" },
                    { text: "Nom d'utilisateur", value: "username" },
                    { text: "Actif", value: "is_active" },
                    { text: "Type", value: "is_admin" },
                    { text: "Actions", value: "actions", sortable: false },
                ],
                items: [],
                dialog: false,
                saving: false,
                form: {
                    id: null,
                    name: null,
                    username: null,
                    password: null,
                    description: null
                },
                defaults: null
            }
        }),
        methods: {
            open() {
                this.data.form = JSON.parse(JSON.stringify(this.$store.state.data));
                let openingHours = this.data.form.opening_hours.split(";");
                for(let i = 0; i < openingHours.length; i++) {
                    this.data.openingHours[i].value = openingHours[i];
                }
                this.dialog = true;

                if(this.$refs.dataForm) {
                    this.$refs.dataForm.resetValidation();
                }
            },

            saveData() {
                //console.log("saveData");
                if(this.$refs && this.$refs.dataForm && this.$refs.dataForm.validate()) {
                    this.data.saving = true;
                    this.data.form.opening_hours = [];
                    for(let i = 0; i < this.data.openingHours.length; i++) {
                        this.data.form.opening_hours.push(this.data.openingHours[i].value ? this.data.openingHours[i].value : "");
                    }
                    this.data.form.opening_hours = this.data.form.opening_hours.join(";");
                    this._apiRequest("data/update", this.data.form).then(response => {
                        if(!response.error) {
                            this.$store.commit({ type: "setData", data: JSON.parse(JSON.stringify(this.data.form)) });

                            this.$root.snackbar.show({
                                text: "Les données ont été sauvegardées avec succées!",
                                icon: "mdi-check",
                                color: "success"
                            });

                            //this.dialog = false;
                        } else {
                            this.$root.snackbar.show({
                                text: response.error,
                                icon: "mdi-alert-circle",
                                color: "error"
                            });
                        }
                    }).finally(() => {
                        this.data.saving = false;
                    });
                }
            },
            loadHomeImage() {
                let file = this.$refs.homeImage && 
                    this.$refs.homeImage.files && 
                    this.$refs.homeImage.files.length > 0 ? 
                    this.$refs.homeImage.files[0] : null;
                if(file) {
                    this.loadImage(file, "home_header_img");
                }
            },
            loadPageImage() {
                let file = this.$refs.pageImage && 
                    this.$refs.pageImage.files && 
                    this.$refs.pageImage.files.length > 0 ? 
                    this.$refs.pageImage.files[0] : null;
                if(file) {
                    this.loadImage(file, "page_header_img");
                }
            },
            loadImage(file, field) {
                let img = new Image();
                img.onload = () => {
                    // Resize
                    let canvas = document.createElement("canvas");
                    let ctx = canvas.getContext("2d");
                    let scale = 1200 / img.width;
                    canvas.width = img.width * scale;
                    canvas.height = img.height * scale;
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    let data = canvas.toDataURL(file.type, 0.9);
                    
                    // Set
                    this.data.form[field] = data;
                }
                img.src = window.URL.createObjectURL(file);
            },

            fetchUsers() {
                this.users.fetching = true;
                this._apiRequest("users/get-all").then(response => {
                    if(!response.error) {
                        if(response.users) {
                            this.users.items = response.users;
                        }
                    } else {
                        this.$root.snackbar.show({
                            text: response.error,
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                    }
                }).finally(() => {
                    this.users.fetching = false;
                });
            },
            openUser(user = null) {
                this.users.form = JSON.parse(this.users.defaults);
                if(user) {
                    user = JSON.parse(JSON.stringify(user));
                    this.users.form = user;
                }
                this.users.dialog = true;
                
                if(this.$refs.userForm) {
                    this.$refs.userForm.resetValidation();
                }
            },
            saveUser() {
                //console.log("saveUser");
                this.users.saving = true;
                this._apiRequest("users/" + (this.users.form.id ? "update" : "create"), this.users.form).then(response => {
                    if(!response.error) {
                        this.$root.snackbar.show({
                            text: "L'utilisateur a été sauvegardé avec succès!",
                            icon: "mdi-check",
                            color: "success"
                        });
                        this.fetchUsers();
                        this.users.dialog = false;
                    } else {
                        this.$root.snackbar.show({
                            text: response.error,
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                    }
                }).finally(() => {
                    this.users.saving = false;
                });
            },
            deleteUser(user) {
                //console.log("deleteUser", user);
                if(confirm("Voulez-vous vraiment supprimer cet utilisateur?")) {
                    user.deleting = true;
                    this._apiRequest("users/delete", { id: user.id }).then(response => {
                        if(!response.error) {
                            this.$root.snackbar.show({
                                text: "L'utilisateur a été supprimé avec succès!",
                                icon: "mdi-check",
                                color: "success"
                            });
                            this.fetchUsers();
                        } else {
                            this.$root.snackbar.show({
                                text: response.error,
                                icon: "mdi-alert-circle",
                                color: "error"
                            });
                        }
                    }).finally(() => {
                        user.deleting = false;
                    });
                }
            },
            updateUserStatus(user) {
                //console.log("updateUserStatus", user);
                user.updatingStatus = true;
                this._apiRequest("users/update-status", { id: user.id }).then(response => {
                    if(!response.error) {
                        this.$root.snackbar.show({
                            text: "L'utilisateur a été " + (user.is_active == 1 ? "désactivé" : "activé") + " avec succès!",
                            icon: "mdi-check",
                            color: "success"
                        });
                        this.fetchUsers();
                    } else {
                        this.$root.snackbar.show({
                            text: response.error,
                            icon: "mdi-alert-circle",
                            color: "error"
                        });
                    }
                }).finally(() => {
                    user.updatingStatus = false;
                });
            }
        },
        created() {
            this.users.defaults = JSON.stringify(this.users.form);
        }
    };
</script>