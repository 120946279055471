<template>
    <v-navigation-drawer
        v-model="model"
        temporary right
        :src="require('./../../assets/img/logo-dark.svg')"
        color="primary"
        fixed
    >
        <div id="overlay"></div>
        <v-list dark>
            <template v-for="(route, i) in routes">
                <v-list-item
                    v-if="route.meta && !route.meta.hidden"
                    :key="i"
                    link
                    :to="route"
                    active-class="secondary--text"
                >
                    <v-list-item-icon>
                        <v-icon>{{ route.meta.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ route.meta.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>

        <template v-slot:append>
            <v-list dark>
                <v-list-item link v-if="$store.state.user && $store.state.user.is_admin">
                    <v-list-item-icon>
                        <v-icon color="grey lighten-2">mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="grey--text text--lighten-2">
                        <v-list-item-title>Paramètres</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="$store.state.user ? $root.loginDialog.logout() : $root.loginDialog.open()">
                    <v-list-item-icon>
                        <v-icon color="grey lighten-2">{{ $store.state.user ? "mdi-logout" : "mdi-login" }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="grey--text text--lighten-2">
                        <v-list-item-title>{{ $store.state.user ? "Déconnexion" : "Connexion" }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </template>
    </v-navigation-drawer>
</template>

<script>
    // Module
    export default {
        name: "sidebar-element",
        props: {
            drawer: {
                type: Boolean,
                required: true
            }
        },
        data: () => ({
            model: false
        }),
        computed: {
            routes() {
                return this.$router.options.routes;
            }
        },
        watch: {
            drawer() {
                this.model = this.drawer;
            },
            model() {
                this.$emit("update", this.model);
            }
        }
    };
</script>

<style scoped>
    #overlay {
        position: absolute;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;
        background-color: #083793;
        opacity: 0.90;
        /*background: #083793;
        background: linear-gradient(170deg, #083793 0%, #f8b21f 95%);
        opacity: 0.95;*/
    }
</style>