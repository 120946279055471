<template>
    <v-text-field
        v-model="query"
        placeholder="Recherche de véhicule... Marque, Modèle, Automatique, Manuel"
        dense
        flat
        hide-details
        :rounded="!fullWidth"
        :full-width="fullWidth"
        :filled="fullWidth"
        :solo-inverted="!fullWidth"
        append-icon="mdi-magnify"
        @click:append="search()"
        @keydown.enter="search()"
        clearable
    ></v-text-field>
</template>

<script>
    // Module
    export default {
        name: "search",
        props: {
            fullWidth: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            query: null
        }),
        methods: {
            search() {
                //console.log("search", this.query);
                if(this.query && this.query.length >= 3) {
                    this.$router.push({
                        name: "inventory",
                        params: {
                            query: encodeURI(this.query).toLowerCase()
                        }
                    }).catch(error => console.log(error.message));
                } else {
                    this.$root.snackbar.show({
                        text: "Vous devez entrer au moins 3 caractères pour effectuer une recherche.",
                        icon: "mdi-alert-circle",
                        color: "warning"
                    });
                    this.$router.push({ name: "inventory" }).catch(error => console.log(error.message));
                }
            }
        }
    };
</script>
