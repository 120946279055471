import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        env: process.env ? process.env : null,
        data: null,
        user: null
    },
    
    mutations: {
        setData(state, payload) {
            state.data = payload.data;
        },
        setUser(state, payload) {
            state.user = payload.user;
        }
    }
});

window.store = store;